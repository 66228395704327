import ServiceHelper from "../../../Utils/Helper/MappingHelper";
import { UPDATE_EXTERNAL_STEP_INFO } from '../../../../Store/Slices/Enrollment';
import { UPDATE_LOADING, SET_ALERT_MESSAGE } from "../../../../Store/Slices/InsuredInfo";
import { onLoadAPI } from '../../../../Store/Slices/Entities/response/index.js';

import { removeUnwantedProps } from '../Helper/MappingHelper';
import resumeContent from '../Content/ResumeContent.json'
export const updateProcess = (params) => async (dispatch, getState) => {

    const storeFromRedux = getState();
    const serviceHelper = new ServiceHelper();

    let updatedState = removeUnwantedProps(storeFromRedux, resumeContent.resumeUnwantedProps);

    if (!storeFromRedux?.process?.enrollmentProcess?.[0]?.[0]?._Result?.processEntity?.buid &&
        !storeFromRedux?.entities?.response?.onLoadResponseList?.GetPendingProcess?._Result?.[0]?.bUID) {
        return false;
    }

    dispatch(UPDATE_LOADING(true));
    try {
        if (params && params.currentStepInfo) {
            params.currentStepInfo.reduxState = updatedState;
            params.currentStepInfo.enrollmentProcessBuid = storeFromRedux?.process?.enrollmentProcess?.[0]?.[0]?._Result?.processEntity?.buid ||
                storeFromRedux?.entities?.response?.onLoadResponseList?.GetPendingProcess?._Result?.[0]?.bUID
        }
        let respObj = await serviceHelper.sendApiRequest('/updateProcess', {
            ...params
        });

        if (respObj.data && respObj.status === 200) {
            if (respObj.data._Result && respObj.data._Result.processEntity && respObj.data._Result.processEntity.externalStepContext) {
                let externalStepContext = JSON.parse(respObj.data._Result.processEntity.externalStepContext);

                dispatch(UPDATE_EXTERNAL_STEP_INFO(externalStepContext));
                 
                dispatch(onLoadAPI({ 'ProcessAPIPet': respObj.data._Result }));
            } else {

            }
            return respObj;
        } else {
            dispatch(SET_ALERT_MESSAGE({
                type: 'error',
                errorCode: respObj?.status || '520',
                id: 'updateProcess',
                show: false,
            }));
            return false;
        }
    }
    catch (e) {
        return false;
    } finally {
        dispatch(UPDATE_LOADING(false));
    }
}

