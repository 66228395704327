import { store } from '../../../../index';
import { CommonConstants } from '../../../../Common/Constants';
export const possibleLabelConstants = {
    'CONTINUE': 'Continue',
    'CONTINUE_TO_NEXT_PRODUCT': 'Continue to Next Product',
    'CONTINUE_TO_SUMMARY': 'Continue to Summary'
}

export const screenNames = {
    INSURED_QUESTIONNAIRE_SCREEN: "questionnaire",
    COVERAGE_SCREEN: "coverage",
    DISCLOSURE_STATEMENT: "disclosure"
}

export const memberTypes = {
    EMPLOYEE: "employee",
    SPOUSE: "spouse",
    CHILD: "child"
}

export const continueBtnLabelHelper = ({
    flowType = 1,
    screenType = screenNames.INSURED_QUESTIONNAIRE_SCREEN,
    nextStep = '',
    hasDisclosureStatement = false,
    isWaivedOffFlag = false,
    spouseCount = 0,
    childrenCount = 0,
    productIdentifierBuid = '',
    memberIdentifier = memberTypes.EMPLOYEE,
    memberDetails = {
        count: 1,
        index: 0,
        eligible: true,
        buid: null
    },
    coverageTableDetails = {
        coverageTableCount: 1,
        coverageIndex: 0
    },
    showEdit = false
}) => {
    const storeState = store.getState();
    const isResume = storeState?.enrollment?.enrollmentType === "resume";
    const additionalDatas = storeState.process?.contractsLinearOrNonLinear?._Result?.contract?.additionalDatas;
    const hasVoyaWL = additionalDatas?.some((data) => data.fieldCode === 'DD_00159' && data.fieldValue === 'Yes');
    const enrollmentPeriodKind = storeState.process?.contractsLinearOrNonLinear?._Result?.currentEnrollmentPeriod?.enrollmentPeriod?.enrollmentPeriodKind;
    const currentStep = storeState.stepFormNew.currentStep;
    const showWaiveCoverage = storeState.insuredInfo[currentStep]?.showWaive;
    const previousStep = storeState.stepFormNew.previousStep || "";
    //let subscribedProducts = storeState?.process?.selectedProductList || storeState?.process?.productList;
    const selecteLinarProduct = storeState?.entities?.response?.onLoadResponseList?.ValidateContractData?._Result?.contract?.subscribedElements;
    let subscribedProducts = ''
    let currentProductIndex = ''
    if(storeState?.process?.selectedProductList &&  storeState?.process?.selectedProductList?.length > 0){
        subscribedProducts = storeState?.process?.selectedProductList
        currentProductIndex = subscribedProducts?.findIndex((item) => item?.memberOfferedProduct?.product?.buid === productIdentifierBuid || item?.bUID === productIdentifierBuid) || 0;
    } else if(selecteLinarProduct &&  selecteLinarProduct?.length > 0){
        subscribedProducts = selecteLinarProduct
        currentProductIndex = subscribedProducts?.findIndex((item) => item?.subscribedProduct?.productCode?.buid === productIdentifierBuid) || 0;
    } else {
        subscribedProducts = storeState?.process?.productList
        currentProductIndex = subscribedProducts?.findIndex((item) => item?.memberOfferedProduct?.product?.buid === productIdentifierBuid || item?.bUID === productIdentifierBuid) || 0;
    }
    if(hasVoyaWL &&enrollmentPeriodKind === "cAnnualEnrollment" ){
        subscribedProducts = [...subscribedProducts,{ bUID: CommonConstants.VOYA_WL}]
    }
    
    //let currentProductIndex = subscribedProducts?.findIndex((item) => item?.memberOfferedProduct?.product?.buid === productIdentifierBuid || item?.bUID === productIdentifierBuid) || 0;
    let totalProductCount = subscribedProducts?.length || 1;

    const productCountCheck = () => {

        // user has one product eligibility
        if (totalProductCount === 1) {
            return possibleLabelConstants.CONTINUE_TO_SUMMARY
        } else {
            // user has multiple products eligibility
            if (totalProductCount === (currentProductIndex + 1)) {
                // user is on last product
                return possibleLabelConstants.CONTINUE_TO_SUMMARY
            } else {
                // user is not on last product
                // user came back from summary page
                if (previousStep === "EnrollmentSummary") {
                    return possibleLabelConstants.CONTINUE_TO_SUMMARY
                } else {
                    // user is on normal flow and has multiple products
                    return possibleLabelConstants.CONTINUE_TO_NEXT_PRODUCT
                }
            }
        }
    }

    const productCountCheckValue = () => {
        
        // user has one product eligibility
        if(totalProductCount === 1) {
            return possibleLabelConstants.CONTINUE_TO_SUMMARY
        } else {
            // user has multiple products eligibility
            if(totalProductCount === (currentProductIndex + 1)) {
                // user is on last product
                return  possibleLabelConstants.CONTINUE_TO_SUMMARY
            } else {
                // user is not on last product
                    // user came back from summary page
                    if(previousStep === "EnrollmentSummary") {
                        return possibleLabelConstants.CONTINUE_TO_SUMMARY
                    } else {
                        // user is on normal flow and has multiple products
                        return  possibleLabelConstants.CONTINUE_TO_SUMMARY
                    } 
            }
        }
    }

    const disclosureStatementCheck = () => {
        // has disclosure statement
        if (hasDisclosureStatement) {
            return possibleLabelConstants.CONTINUE
        } else {
            // does not have disclosure statement
            return productCountCheck()
        }
    }

    const showWaiveCoverageOptionCheck = () => {
        if(!showWaiveCoverage && memberDetails.eligible) {
            return possibleLabelConstants.CONTINUE
        } 
        else if(!memberDetails.eligible){
            return productCountCheckValue();
        }
        else {
            return disclosureStatementCheck()
        }
    }

    const disclosureScreenScenarios = () => {
        return productCountCheck()
    }

    const questionnaireScreenScenarios = () => {
        let buttonLabel;
        const employeePageCheck = () => {
            //Possible scenarios
            // 1. user has no value selected
            buttonLabel = possibleLabelConstants.CONTINUE
            if (memberDetails.eligible === false && showEdit === true) {
                return productCountCheck();
            } else {
                if (isWaivedOffFlag === null) {
                    buttonLabel = possibleLabelConstants.CONTINUE
                } else {
                    //user does not have waive off coverage checkbox
                    if (isWaivedOffFlag) {
                        if (!showWaiveCoverage) {
                            return possibleLabelConstants.CONTINUE
                        } else {
                            return productCountCheck();
                        }
                    }
                }
            }



            // 3. user selects answers -> some value is selected on screen but not waiveoff
            // product have/does not have, disclosure statement, user will be navigated to coverage selection page
            // all above cases will show continue label
            // it also does not have anything to do with eligibility
            return buttonLabel;
        }

        const spousePageCheck = () => {
            //possible scenarios
            // 1. user has waive off selected
            // 2. user has value selected
            // both scenarios user either move to next child or disclosure or next product
            // user has insured child
            // it does not matter if spouse is eligible or not at this stage
            if (childrenCount || memberDetails.eligible) {
                return possibleLabelConstants.CONTINUE
            } 
            else if(!memberDetails.eligible){
                //return productCountCheckValue();
                return disclosureStatementCheck();
            }
            
            else {
                // user does not have insured child
                // has disclosure statement
                showWaiveCoverageOptionCheck()

            }
        }

        const childrenPageCheck = () => {
            switch (flowType) {
                case 2: {
                    //if childcount is one or last child in queue
                    if(childrenCount === 1 || (memberDetails.index + 1) === childrenCount && memberDetails.eligible) {
                        // child eligible
                        showWaiveCoverageOptionCheck()
                    }    
                    else {
                        return possibleLabelConstants.CONTINUE
                    }
                }

                case 3: {
                    //Possible scenarios
                    // if child eligible
                    if (memberDetails.eligible) {
                        // 1. user has waive off selected
                        if (isWaivedOffFlag) {
                            // product has disclosure statement
                            showWaiveCoverageOptionCheck()
                        } else {
                            // 2. user has value selected
                            return possibleLabelConstants.CONTINUE
                        }
                    }
                    //check for discloure sttement to show 
                    else if(!memberDetails.eligible){
                        //return productCountCheckValue();
                         return disclosureStatementCheck();
                    }
                    else {
                        // if all children are in-eligible
                        return productCountCheckValue()
                    }
                }
            }
        }

        switch (flowType) {
            case 1: {
                return employeePageCheck();
            }

            case 2: {
                switch (memberIdentifier) {
                    case "employee": {
                        return employeePageCheck()
                    }

                    case "spouse": {
                        return spousePageCheck()
                    }

                    case "children": {
                        return childrenPageCheck()
                    }
                }
            }

            case 3: {
                switch (memberIdentifier) {
                    case "employee": {
                        return employeePageCheck()
                    }

                    case "spouse": {
                        return spousePageCheck()
                    }

                    case "children": {
                        return childrenPageCheck()
                    }
                }
            }

            case 4: {
                return employeePageCheck();
            }

            case 5: {
                if (isWaivedOffFlag === null || isWaivedOffFlag === false) {
                    return disclosureStatementCheck()
                } else {
                    // 2. user selects waiveoff
                    if (isWaivedOffFlag) {
                        return productCountCheck();
                    }
                }
            }

            case 6: {
                buttonLabel = possibleLabelConstants.CONTINUE
                if (isWaivedOffFlag === null) {
                    return disclosureStatementCheck()
                } else {
                    // 2. user selects waiveoff
                    if (isWaivedOffFlag) {
                        return productCountCheck();
                    }
                }

                return buttonLabel;
            }

        }
    }

    const coverageScreenScenarios = () => {
        let buttonLabel;

        const employeePageCheck = () => {
            //possible scenarios
            // 1. user has no value selected
            if (isWaivedOffFlag === null) {
                buttonLabel = possibleLabelConstants.CONTINUE
            } else {
                // 2. user selects waiveOff
                if (isWaivedOffFlag) {
                    return productCountCheck()
                } else {
                    if (flowType === 2 || flowType === 3) {
                        if (!spouseCount && !childrenCount) {
                            return disclosureStatementCheck()
                        } else {
                            buttonLabel = possibleLabelConstants.CONTINUE
                        }

                    } else {
                        // 3. user selects a value
                        buttonLabel = possibleLabelConstants.CONTINUE
                    }
                }
            }

            return buttonLabel
        }

        const spousePageCheck = () => {
            //possible scenarios
            // 1. user has waive off selected
            // 2. user has value selected
            // both scenarios user either move to next child or disclosure or next product
            // user has insured child
            if (childrenCount) {
                return possibleLabelConstants.CONTINUE
            } else {
                // user does not have insured child
                // has disclosure statement
                return disclosureStatementCheck()
            }
        }

        const childrenPageCheck = () => {

            switch (flowType) {

                case 2: {
                    if (childrenCount === 1 || (memberDetails.index + 1) === childrenCount) {
                        return disclosureStatementCheck()
                    } else {
                        return possibleLabelConstants.CONTINUE
                    }
                }

                case 3: {
                    // product has disclosure statement
                    return disclosureStatementCheck()
                }
            }
        }

        switch (flowType) {
            case 1: {
                //Possible scenarios
                // 1. user has no value selected
                buttonLabel = possibleLabelConstants.CONTINUE;
                // 2. user selects waiveoff
                if (isWaivedOffFlag) {
                    buttonLabel = productCountCheck();
                } else {
                    // 3. user selects a coverage -> some value is selected on screen but not waiveoff
                    // has disclosure statement
                    return disclosureStatementCheck()

                }
                return buttonLabel;
            }

            case 2: {
                switch (memberIdentifier) {
                    case "employee": {
                        return employeePageCheck()
                    }

                    case "spouse": {
                        return spousePageCheck()
                    }

                    case "children": {
                        return childrenPageCheck()
                    }
                }
            }

            case 3: {
                switch (memberIdentifier) {
                    case "employee": {
                        return employeePageCheck()
                    }

                    case "spouse": {
                        return spousePageCheck()
                    }

                    case "children": {
                        return childrenPageCheck()
                    }
                }
            }

            case 4: {
                //possible scenarios
                //1. user of coverage table 1
                if (coverageTableDetails.coverageIndex === 0) {
                    return employeePageCheck()
                } else if (coverageTableDetails.coverageTableCount === (coverageTableDetails.coverageIndex + 1)) {
                    //2. user on additional coverage table or table 2
                    return disclosureStatementCheck();
                }

            }

            default: {
                return possibleLabelConstants.CONTINUE
            }
        }
    }

    switch (screenType) {
        case screenNames.INSURED_QUESTIONNAIRE_SCREEN: {
            return questionnaireScreenScenarios();
        }

        case screenNames.COVERAGE_SCREEN: {
            return coverageScreenScenarios()
        }

        case screenNames.DISCLOSURE_STATEMENT: {
            return disclosureScreenScenarios()
        }
    }
}
